
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { getAndGetOnNewLanguage } from '@/api/base';
import { toInfoUrl, info } from '@/api/info';
import routeParamToString from '@/router/param_as_string';
import Press from '@/components/info/Press.vue';
import ImageHolder from '@/components/global/ImageHolder.vue';
import { isLoaded } from '@/interfaces/RemoteData';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import SectionHeader from '@/components/global/SectionHeader.vue';
import RemoteContent from '@/components/global/RemoteContent.vue';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ImageHolder,
    Press,
    ArticleContainer,
    SectionHeader,
    RemoteContent,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    const title = computed(
      () => '',
    );
    const description = computed(
      () => 'Corpus Coranicum',
    );
    const canonical = computed(
      () => `https://corpuscoranicum.org/${route.params.lang}`,
    );
    const alternate_de = computed(
      () => 'https://corpuscoranicum.org/de',
    );
    const alternate_en = computed(
      () => 'https://corpuscoranicum.org/en',
    );
    const alternate_fr = computed(
      () => 'https://corpuscoranicum.org/fr',
    );
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        {
          rel: 'canonical', href: canonical.value,
        },
        {
          rel: 'alternate', hreflang: 'de', href: alternate_de.value,
        },
        {
          rel: 'alternate', hreflang: 'en', href: alternate_en.value,
        },
        {
          rel: 'alternate', hreflang: 'fr', href: alternate_fr.value,
        },
        {
          rel: 'alternate', hreflang: 'x-default', href: alternate_de.value,
        },
      ],
      htmlAttrs: { lang: route.params.lang },
    }));
    useMeta(meta);
    getAndGetOnNewLanguage(toInfoUrl('start', routeParamToString(route.params.lang)), info, route);
    const publicPath = process.env.BASE_URL;
    return {
      info,
      isLoaded,
      publicPath,
    };
  },
});
